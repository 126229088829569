<template >
<div>
    <div class="info-box  bg-sheme  text-white">
        <div class="info-icon bg-sheme-dark" style="padding:5px;">
            <h5><i class="fa fa fa-user fa-2x"></i>Ratings</h5>
        </div>
        <div class="info-details" style="padding-left:2px">
                <div class="col-md-12 row">
                    <div class="col-md-3">
                        <h5>Customer ID- </h5>
                    </div>
                    <div class="col-md-6">
                        <h3 style="margin-right:2px" class="custid">{{ edititem.generatedid}}</h3>
                    </div>
                    <div class="col-md-3" >
                        
                    </div>
                </div>
            </div>
    </div>
    <div>
        <table class="table table-striped-sm-bordered-hover-inverse table-inverse">
            <thead class="table-font">
                <tr>
                    <th>#</th>
                    <th>Rating Type</th>
                    <th>Ratings</th>
                    <th>Date of Active</th>
                    <th>Activity By</th>
                    <th>Date of Activity</th>
                </tr>
            </thead>
            <tbody class="table-font">
                <tr v-for="(item,index) in ratings" v-bind:key="item.id">
                    <td>{{ index+1 }}</td>
                    <td>{{ item.ratingtype }}</td>
                    <td>{{item.ratingremark}}</td>
                    <td>{{moment(item.ratingdateofactive).format("DD-MM-YYYY")}}</td>
                    <td><div v-if="item.enteredby!=null">{{item.enteredby.name}}</div></td>
                    <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}</td>
                </tr>
            </tbody>
        </table>
    </div>


</div>
</template>
<script>
import moment from 'moment'
// import { mapGetters } from 'vuex'
import Constants from '../utilities/Constants.vue'
export default {
    mixins:[Constants],
    props:['edititem'],
    data() {
        return {
            moment:moment,
            ratings:[]
        }
    },
    // computed:{
    //     ...mapGetters([
	// 	    'edititem'
	//     ]),
    // },
    mounted() {
        this.refresh();
    },
    methods:{
        refresh(){
            let param={id:this.edititem.id}
            this.$http.post('api/fetch/ratings',param)
                .then((response) => this.processratingResponse(response.data))
                .catch((err) => {
                console.log(err)
            });
        },
        processratingResponse(data){
            this.ratings=data
        },
        back(){
            // this.localviewno=1;
            this.$store.commit("assignviewno",3)
        },
        
    },
 
}
</script>