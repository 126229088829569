<template>
<div>
    <div v-if="basicviewno==2">
        <div class="info-box  bg-sheme  text-white">
            <div class="info-icon bg-sheme-dark">
                <h5 style="margin-top:12px">Account Details</h5>
            </div>
            <div class="info-details" style="padding-left:2px">
                <div class="col-md-12 row">
                    <div class="col-md-3">
                        <h5> </h5>
                    </div>
                    <div class="col-md-6">
                        <h3 style="margin-right:2px" class=""></h3>
                    </div>
                    <div class="col-md-3" >
                        
                    </div>
                </div>
            </div>
        </div>
        <div>
            <table class="table table-striped-sm-bordered-hover-inverse table-inverse">
                <thead class="table-font">
                    <tr>
                        <th>#</th>
                        <th>Account No.</th>
                        <th>Product Name</th>
                        <th>Sale date</th>
                        <th>S.P.</th>
                        <th>Remaining Amount</th>
                        <th>Sale By</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody class="table-font">
                    <tr v-for="(item,index) in customersales" v-bind:key="item.id">
                        <td>{{index+1}}</td>
                        <td><a v-if="item.loandetails!=null" href="#" @click="loandetails(item.loandetails.id)">
                                <div >
                                    <span style="font-size:11px"> {{item.loandetails.manualid}}</span>
                                </div>
                            </a>
                            <a v-if="item.scheme!=null" href="#" @click="details(item)">
                                <div >
                                    <span style="font-size:11px"> {{item.generatedid}}</span>
                                </div>
                            </a>
                        </td>
                        <td>
                            <div v-for="item2 in item.items" :key="item2.id">
                                <div>
                                    <span v-if="item2.product!=null && item2.product.category!=null">{{item2.product.category.name}}</span>
                                    <span v-if="item2.product!=null && item2.product.attribute!=null">
                                        <div v-for="company in item2.product.attribute" :key="company.id">
                                        {{ getcompany(company.attribute_value_ids) }}
                                        </div>
                                    </span>
                                    <span >{{item2.product.name}}</span>&nbsp;
                                    <!-- <span >{{item2.product.modelno}}</span> -->
                                </div>
                            </div>
                        </td>
                        <td>
                            <div v-if="item.loandetails!=null">
                                    <span style="font-size:11px"><i class="bx bx-calendar-alt"></i> {{moment(item.transactiondate).format('DD-MM-YYYY')}}</span>
                            </div>
                            <div v-if="item.scheme!=null">
                                <span style="font-size:11px"><i class="bx bx-calendar-alt"></i> {{moment(item.saledate).format('DD-MM-YYYY')}}</span>
                            </div>
                        </td>
                        <td>
                            <div v-if="item.loandetails!=null">
                                    <span style="font-size:11px"><i class='bx bx-rupee'></i> {{item.amtafterdiscount}}</span>
                            </div>
                            <div v-if="item.scheme!=null">
                                <span style="font-size:11px"><i class='bx bx-rupee'></i> {{item.billedamount}}</span>
                            </div>
                        </td>
                        <td>
                            <div v-if="item.loandetails!=null">
                                    <span style="font-size:11px"><i class='bx bx-rupee'></i> {{item.loandetails.remainingamount}}</span>
                            </div>
                            <div v-if="item.scheme!=null">
                                <span style="font-size:11px"><i class='bx bx-rupee'></i> {{item.remainingamount}}</span>
                            </div>
                        </td>
                        <td>
                            <div v-if="item.loandetails!=null">
                                <span style="font-size:11px" v-if="item.saleby!=null"> {{item.saleby.name}}<br><i class="bx bx-calendar-alt"></i> {{moment(item.created_at).format('DD-MM-YYYY')}}
                                <br><i class="bx bx-time-five"></i> {{moment(item.created_at).format('hh:mm:ss A')}}</span>
                            </div>
                            <div v-if="item.scheme!=null">
                                <span style="font-size:11px" v-if="item.seller!=null"> {{item.seller.name}}<br><i class="bx bx-calendar-alt"></i> {{moment(item.created_at).format('DD-MM-YYYY')}}
                                <br><i class="bx bx-time-five"></i> {{moment(item.created_at).format('hh:mm:ss A')}}</span>
                            </div>
                        </td>
                        <td>
                            <div v-if="item.loandetails!=null">
                                {{ setaccounttype(item) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <on-going-scheme-details v-if="basicviewno==3"></on-going-scheme-details>
    <!-- <sales-details v-if="basicviewno==4"></sales-details> -->
</div>
</template>
<script>
import moment from 'moment';
import OnGoingSchemeDetails from '../ongoingschemes/OnGoingSchemeDetails.vue';
// import SalesDetails from '../salesmanager/SalesDetails.vue';
import { mapGetters } from 'vuex';
export default {
    props:['edititem'],
    components:{OnGoingSchemeDetails},
    data() {
        return {
            
            sales:[],
            schemes:[],
            moment:moment,
            basicviewno:2
        }
    },
    computed:{
        ...mapGetters(['viewno','attributes']),

        customersales:function(){
            return this.sales.concat(this.schemes)
        }
    },
    mounted() {
        this.refresh();
        
    },
    methods: {
        refresh(){
            //fetching sales of customer
            this.$store.dispatch("fetchattributes")
            let param1 = {customerid:this.edititem.id}
            this.$http.post('api/saletransactions/datewise',param1)
            .then((response) => this.processSaleResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
            this.$http.post('api/ongoingschemes/fetch',param1)
            .then((response) => this.processSchemeResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processSaleResponse(data){
            this.sales=data
        },
        processSchemeResponse(data){
            this.schemes=data
        },
        loandetails(id){
           let param = {id:id}
            this.$http.post('api/loan/details',param)
            .then((response) => this.processloanResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processloanResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assignedititem',data)
            // this.basicviewno=4
            this.$parent.localviewno=3
        },
        details(item){
            let param = {id:item.id}
            this.$http.post('api/ongoingschemes/details',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processDetailResponse(data){
            this.$store.commit('assignidproofs',data.customer.idproofs==null?[]:data.customer.idproofs)
            this.$store.commit('assignedititem',data)
            this.basicviewno=3
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        setaccounttype(item){
            if(item.loandetails.accountstatus==0){
                return "Active "
            }else if(item.loandetails.accountstatus==1){
                return "Clear "
            }else if(item.loandetails.accountstatus==2){
                return "Defaulter"
            }else if(item.loandetails.accountstatus==3){
                return "Legal"
            }else if(item.loandetails.accountstatus==4){
                return "Concesation"
            }else if(item.loandetails.accountstatus==5){
                return "Closed"
            }
        },
        
    },
}
</script>