<template>
<tbody class="table-font">
    <tr v-if="item!=null">
        <td>{{index+1}}</td>
        <td>{{ item.id }} </td>
        <td><i class='bx bx-rupee'></i> {{item.amount}} </td>
        <td><i class='bx bx-rupee'></i> {{item.payment!=null?item.payment.amount:0}} </td>
        <td><i class='bx bx-calendar-alt'></i> {{moment(item.emidate).format('DD-MM-YYYY')}}</td>
        <td><i class='bx bx-calendar-alt'></i> {{item.payment!=null?moment(item.payment.created_at).format("DD-MM-YYYY"):''}}</td>
        <td>{{item.payment!=null?item.payment.receiptno:''}}</td>
        <td>{{item.payment!=null?getusername(item.payment.collectedbyid):''}}</td>
        <td><i class='bx bx-calendar-alt'></i> {{item.payment!=null?moment(item.payment.created_at).format("DD-MM-YYYY"):''}}</td>
        <td><button class="badge bg-sheme"><i class="fa fa-envelope"></i> {{ countmessages }}</button></td>
        <td>{{item.payment!=null?item.payment.enteredby.name:''}}</td>
        <td style="padding-left:0px;padding-right:0">
            <button v-if="item.paymenttransactionid == 0" class="btn btnsearch btn-view" type="button" @click="payemi()" style="background-color:#179b17!important;padding:7px;font-size:9px;border-radius:25px;">
                <!-- <i class="fa fa-arrow-up"></i>Pay Emi -->
                <i v-if="showpaymentform==false" class="fa fa-arrow-up"></i>
                <i v-else class="fa fa-arrow-down"></i> Pay Emi
            </button>
            <button class="badge btn-view" v-if="item.paymenttransactionid != 0 ">{{item.payment.paymentregister!=null ? item.payment.paymentregister.name: ''}}</button>
        </td>
    </tr>
    <tr>
        <td colspan="4">
            <button class="btn btnsearch btn-view" type="button" @click="visitcomment()" style="margin-right:8px;background-color:#334a5b!important;padding:7px;font-size:9px;border-radius: 25px;">
                <i class="fa fa-plus"></i>  View details
            </button>
            <button class="btn bg-sheme" type="button" style="margin-right:8px;padding:4px;font-size:10px;border-radius:25px;width:24px;">0</button>
            <button class="btn btn-danger" type="button" style="padding:3px;font-size:12px;border-radius:7px;">N/A</button>
        </td>
    </tr>
    <tr v-if="showpaymentform && item.paymenttransactionid == 0">
        <td colspan="11">
            <emi-payment-form :emiitem="item"></emi-payment-form>
        </td>
    </tr>
    <tr v-if="visitcomments">
        <td colspan="11">
            <emi-visit-comment :emiid="item.id"></emi-visit-comment>
        </td>
    </tr>
</tbody>
</template>
<script>
import { mapGetters } from 'vuex'
import EmiPaymentForm from './EmiPaymentForm.vue'
import EmiVisitComment from './EmiVisitComment.vue'
import Constants from '../../components/utilities/Constants.vue'
import moment from "moment";
export default {
    mixins:[Constants],
    components:{EmiPaymentForm,EmiVisitComment},
    data() {
        return {
            showpaymentform:false,
            visitcomments:false,
            moment:moment
        }
    },
    props:['emiid','index'],
    computed:{
        ...mapGetters([
		      'edititem'
	    ]),
        item(){
            if(this.edititem!=null && this.edititem.emis!=null){
                let emi = this.edititem.emis.find(block=>block.id==this.emiid)
                if(typeof emi!=='undefined')
                    return emi
            }
            return null;
        },
        countmessages(){
            if(this.item!=null){
                return this.item.visitcomments.length
            }
            return 0
        }
    },
    methods:{
       
        payemi(){
            this.showpaymentform = !this.showpaymentform
            //this.$store.commit('assignedititem2',this.item)
        },
        visitcomment(){
            this.visitcomments = !this.visitcomments;
        }
    }
}
</script>