<template>
<div>
    <table class="table table-bordered table-font" style="margin-bottom:0;color:#279d00;font-size:13px!important;">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Conversation</th>
                <th>Entered BY</th>
                <th>Schedule Date</th>
                <th>Followed By</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,index) in list" v-bind:key="item.id">
                <td>{{ index+1 }}</td>
                <td>{{ item.detail }}</td>
                <td>{{ item.enteredby!=null?item.enteredby.name:''}}<br>
                    {{ moment(item.created_at).format("DD-MM-YYYY") }}</td>
                <td>{{ item.scheduledate!=null?moment(item.scheduledate).format("DD-MM-YYYY"):'N.A'}}</td>
                <td>{{ item.followby!=null?item.followby.name:''}}</td>
            </tr>
        </tbody>
    </table>
     <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#d0e5cc">
                <div class="form-horizontal cascde-forms">
                    <div class="form-group row" v-if="loggedinuser.role>=2 || loginusercheck(184)">
                        <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small">
                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                            </select>             
                            <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                        </div>
                    </div>
                    <!-- <div class="form-group flex-row" v-if="loggedinuser.role<2 ">
                        <label class="col-lg-4 col-md-3 control-label">Followed By<span style="color:red">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small">
                                <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id" ><span >{{filter.name}}</span></option>
                            </select>             
                            <font color="red"><span class="field-validation-valid" data-valmsg-for="FOLLOWEDBYID" data-valmsg-replace="true"></span></font>
                        </div>
                    </div> -->
                    <div class="form-group row" >
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Conversation<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="detail" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                        </div>
                        <!-- <div class="col-lg-2">
                            <button class="badge btn-prime btn-xs" @click="shownewlead=!shownewlead">New Lead</button>
                        </div> -->
                    </div>
                     <div class="form-group row" v-if="!dispose">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">Schedule Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <input class="form-control form-cascade-control input-small" :disabled="showdate" v-model="scheduledate"  type="date" >
                        </div>
                        <div class="col-lg-2" >
                            <button class="badge btn-prime btn-xs"  @click="showdate=!showdate">Schedule</button>
                        </div>
                    </div>
                     <!-- <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">Followed By<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select v-model="followedid" class="form-control form-cascade-control input-small">
                                <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                            </select> 
                        </div>
                    </div> -->
                </div>
                <div class="form-group row">
                    <div class="col-md-3"></div>
                    <div class="col-md-5 flex-around-row">
                        <button v-if="!dispose" type="button" class="btn btn-primary" style="margin-top:0" @click="submit()">Schedule</button>
                        <button v-if="dispose" type="button" class="btn btn-danger" @click="disposeoff()">Close Sale</button>
                    </div>
                </div>
                <!-- <p style="color:#2196f3;font-size:13px">* If You want to Dispose Lead, You don't need to fill Schedule Date</p> -->
            </div>
            
        </div>
    </div>
</div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Constants from '../../components/utilities/Constants.vue';
export default {
    mixins:[Constants],
    props:['item','dispose'],
    data() {
        return {
            id:0,subject:'',detail:'',scheduledate:'',followedid:0,
            list:[],
            moment:moment,
            showdate:false
        }
    },
    validations:{
        scheduledate:{
            required
        }
    },
    computed:{
        ...mapGetters([
		   'loggedinuser','employees'
	    ]),
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh(){
            this.$store.dispatch('fetchemployees')
            let custid;
            if(this.item.user!=null){
                custid=this.item.customerid
            }else{
                custid=this.item.id
            }
            let param = {userid:custid}
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/leads/customerconversation/fetch',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
               console.log(err)
            });
        },
        processDetailResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.list=data
        },
        submit(){
            if(this.$v.$invalid){
              this.$notify({
                  text:'Schedule Date is Compulsory',
                  type:'error',
                  duration:'50000',
                  speed:'300',
                  closeOnClick:false
              })
            }else{
                let custid;
                if(this.item.user!=null){
                    custid=this.item.customerid
                }else{
                    custid=this.item.id
                }
                let param = {userid:custid,detail:this.detail,scheduledate:this.scheduledate,
                        followedid:this.followedid}
                    this.$http.post('api/leads/customerconversation',param)
                    .then((response) => this.processDetail(response.data))
                    .catch((err) => {
                       console.log(err)
                    });
            }
        },
        processDetail(){
            this.$notify({
                  text:'Customer is Scheduled',
                  type:'success',
                  duration:'50000',
                  speed:'300',
                  closeOnClick:false
              })         
            this.$parent.visitcomments = false
            this.$parent.refresh();
        },
        disposeoff(){
            let param = {userid:this.item.id,detail:this.detail,
                        followedid:this.followedid}
            this.$store.commit('assignloadingstatus',1)
            this.$http.post('api/crm/disposed/disable',param)
            .then((response) => this.processDetailResponsedispose(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processDetailResponsedispose(){
            this.$store.commit('assignloadingstatus',0)
            this.$parent.visitcomments = false
            this.$parent.refresh();
            this.$notify({
                text:'Customer is Re-Active on Customer Data',
                type:'success',
                duration:'50000',
                speed:'300',
                closeOnClick:false
            })  

        },
        
    },
}
</script>