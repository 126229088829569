<template>
<div v-if="this.edititem!=null">
    <div style="background-color:white" v-if="localviewno==1">
            <div class="info-box  bg-sheme  text-white">
                <div class="info-icon bg-sheme-dark" style="padding:10px;">
                    <h5 style="margin-top:12px">Customer Details</h5>
                </div>
                   <div class="info-details" style="padding-left:2px">
                    <div class="col-md-12 row">
                    <div class="col-md-3">
                        <h5>Customer ID- </h5></div>
                        <div class="col-md-6">
                            <h3 style="margin-right:2px" class="custid">
                                <span v-if="this.edititem.customer!=null">{{ this.edititem.customer.generatedid }}</span>
                            </h3>
                            <h3 class="custid">{{ this.edititem.generatedid }}</h3>
                        </div>
                        <div class="col-md-3" >
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel panel-cascade" style="padding-bottom:20px">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <table class="table table-bordered ">
                                <tbody class="table-font">
                                    <tr v-if="this.edititem.customer!=null">
                                        <td class="mw-30" style="padding-bottom:0px;width:25%;">
                                            <div class="text-left text">Photo :</div>
                                                <div class="text1"><img :src="profileimageurl" width="50px" height="50px"/></div>
                                        </td>
                                        <td class="mw-30">
                                            <div class="text-left text">NAME:</div>
                                                <div class="text1  "><span v-if="this.edititem.customer!=null">{{ this.edititem.customer.name }}</span></div>
                                        </td>
                                        <td >
                                            <div class="text-left text ">Mobile No:</div>
                                                <div class="text1  "><display-mobiles :mobiles="this.edititem.customer.mobiles"></display-mobiles></div>
                                        </td>
                                        
                                        <td style="padding-bottom:0px">
                                                <div class="text-left text ">Introducer:</div>
                                                <div class="text1" >
                                                <template v-if="this.edititem.customer.referraluser!=null">
                                                    {{ this.edititem.customer.referraluser.name }}
                                                    {{ this.edititem.customer.referraluser.generatedid }}
                                                    <display-mobiles :mobiles="this.edititem.customer.referraluser.mobiles"></display-mobiles>
                                                </template>
                                                </div>
                                        </td>
                                    </tr>
                                    <tr v-if="this.edititem.customer!=null">
                                        <td style="width:20%;">
                                                <div class="text-left text ">Address:</div>
                                                <div class="text1  "><display-first-address :addresses="this.edititem.customer.addressess"></display-first-address></div>
                                        </td>
                                        <td >
                                                <div class="text-left text ">Landmark:</div>
                                                <div class="text1" >None</div>
                                        </td>
                                        <td >
                                                <div class="text-left text ">Deal By:</div>
                                                <div class="text1" ><template v-if="this.edititem.seller!=null">{{this.edititem.seller.name}}</template></div>
                                        </td>
                                        <td >
                                                <div class="text-left text ">Verified By:</div>
                                                <div class="text1" ><template v-if="this.edititem.verified!=null">{{this.edititem.seller.verified}}</template></div>
                                        </td>
                                        
                                    </tr>
                                    <tr v-if="this.edititem.customer!=null">
                                        <td scope="row">
                                            <div class="text-left text ">First Document:</div>
                                            <div class="text1">
                                                <display-id-proof-front-back  :labelid="1" frontorback="1"></display-id-proof-front-back>     
                                           </div>

                                        </td>
                                        <td>
                                            <div class="text-left text ">Second Document:</div>
                                            <div class="text1">
                                                <display-id-proof-front-back  :labelid="2" frontorback="1"></display-id-proof-front-back>
                                             </div>
                                        </td>
                                        <td>
                                            <div class="text-left text ">Third Document:</div>
                                            <div class="text1 ">
                                                <display-id-proof-front-back :labelid="3" frontorback="1"></display-id-proof-front-back>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-left text ">Fourth Document:</div>
                                            <div class="text1 ">
                                                <display-id-proof-front-back :labelid="4" frontorback="1"></display-id-proof-front-back>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="this.edititem.customer!=null">
                                        <td scope="row">
                                            <div class="text-left text ">Zone:</div>
                                            <div class="text1 ">{{ this.edititem.customer.zone }}</div>
                                        </td>
                                        <td >
                                            <div class="text-left text ">Customer Type:</div>
                                            <div class="text1" ><template v-if="this.edititem.customer.customer_type!=null">{{this.edititem.customer.customer_type}}</template></div>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="info-box  bg-sheme  text-white" >
                <div class="info-icon bg-sheme-dark" style="padding:5px;">
                    <h5>  <i class="fa fa fa-user fa-2x"></i>Bill Details</h5>
                </div>
                <div class="info-details">
                    <h4>Customer ID</h4>
                </div>
            </div>
            <div class="panel panel-cascade" style="padding-bottom:20px;">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <table class="table table-sm table-inverse">
                            <thead class="thead-inverse|thead-default">
                                <tr class="table-font">
                                    <th>Date</th>
                                    <th>Total Amount</th>
                                    <!-- <th>Down Payment</th> -->
                                    <th>Emi Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Remg Amount</th>
                                    <th>Followed by</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="table-font">
                                <tr>
                                    <td scope="row">{{ this.edititem.saledate }}</td>
                                    <td><span v-if="edititem.scheme!=null">{{ this.edititem.scheme.totalamount }}</span>  </td>
                                    <!-- <td>0</td> -->
                                    <td><span v-if="edititem.scheme!=null">{{ this.edititem.scheme.emi }}</span></td>
                                    <td><span>{{edititem.totalreceived}}</span></td>
                                    <td>{{ edititem.remainingamount }}</td>
                                    <td colspan="6"> 
                                        <select class="form-control form-cascade-control input-small" style="height:31px" v-model="followedid">
                                            <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </td>   
                                    <td > 
                                        <button type="button" class="btn btn-view" style="background-color:#3086b9!important;border-radius:inherit;padding:2px;" @click="updatefollowed()"> Update</button>
                                    </td>
                                    <td >    
                                        <button type="button" class="btnsearch btn-view" style="padding:0px!important;width:59px!important;"  @click="followeddetails()"><i class="fa fa-exclamation"></i>  View</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
            <div class="info-box  bg-sheme  text-white">
                <div class="info-icon bg-sheme-dark" style="padding:5px;">
                    <h5>  <i class="fa fa fa-user fa-2x"></i>Emi Details</h5>
                </div>
            </div>
            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <table class="table table-sm table-hover table-inverse table-striped display">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>EMI NO</th>
                                    <th>Emi Amount</th>
                                    <th>Rec. Amount</th>
                                    <th>Emi Date</th>
                                    <th>Received Date</th>
                                    <th>Receipt No</th>
                                    <th>Collected By</th>
                                    <th>Entry Date</th>
                                    <th>Message</th>
                                    <th>Entry By</th> 
                                </tr>
                            </thead>
                            
                                <emi-row v-for="(item,index) in this.edititem.emis" v-bind:key="item.id" :index="index" :emiid="item.id"></emi-row>
                            
                        </table>
                    </div>
                </div>
            </div>
    </div>
            

    <followed-by-details v-if="localviewno==2"></followed-by-details>

    
    
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import EmiRow from '../../components/emi/EmiRow.vue'
import FollowedByDetails from '../../components/followedby/FollowedByDetails.vue'
import DisplayIdProofFrontBack from '../../components/idproof/DisplayIdProofFrontBack.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import Constants from '../../components/utilities/Constants.vue'
export default {
  components: { DisplayMobiles, DisplayFirstAddress, DisplayIdProofFrontBack, EmiRow,FollowedByDetails},
    mixins:[Constants,FollowedByDetails],
    data(){
        return {
            localfollowedid:0,
            localviewno:1
        }
    },
    mounted(){
        console.log("Details",this.edititem)
        console.log(this.employees)
    },
    computed:{
        profileimageurl(){
            if(this.edititem!=null){
                if(this.edititem.profileimage!=null){
                    return this.edititem.profileimage.fullurl;
                }
            }
            return '#'
        },
        ...mapGetters([
		      'this.edititem','employees','followedbydetails','viewno'
	    ]),
        followedid:{
            get(){
                if(this.edititem!=null){
                        if(this.edititem.followed!=null){
                            //get the max id in the list
                            let maxid = Math.max.apply(Math, this.edititem.followed.map(function(o) { return o.id; }))
							let found = this.edititem.followed.find(block=>block.id==maxid)
                            if(typeof found!=='undefined'){
                                return found.userid
                            }
                            
                        }
                    }
                return 0
            },
            set(id){
                this.localfollowedid=id;
            }
        }
    },
    methods:{
        updatefollowed(){
            let param = {id:this.edititem.id,userid:this.localfollowedid}
            this.$http.post('api/ongoingschemes/followedby/createupdate',param)
            .then((response) => this.processDetailResponse(response.data))
            .catch((err) => {
                console.log(err);
            });
        },
        processDetailResponse(data){
            this.$store.commit('assignthis.edititem',data)
        },
        paidamount(){
            return 0;
        },
        remainingamount(){
            return 0;
        },
        followeddetails(){
            this.localviewno = 2
        },
        
    }
}
</script>