<template>
<div>
    <table class="table table-bordered table-font" style="margin-bottom:0;color:#279d00;font-family:auto!important;font-size:17px!important;">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Message</th>
                <th>Followed By</th>
                <th>EMI Date</th>
                <th>OverDues Date</th>
                <th>Entry Date</th>
                <th>Entry By</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,index) in list" v-bind:key="item.id">
                <td>{{ index+1 }}</td>
                <td>{{ item.comments }}</td>
                <td>{{ item.visitor!=null?item.visitor.name:'' }}</td>
                <td>{{ item.emi!=null?moment(item.emi.emidate).format("DD-MM-YYYY"):'' }}</td>
                <td>{{ moment(item.nextpaymentdate).format("DD-MM-YYYY") }}</td>
                <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}</td>
                <td>{{ item.enteredby!=null?item.enteredby.name:'' }}</td>
            </tr>
        </tbody>
    </table>
    <div class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#d0e5cc">
                <div class="form-horizontal cascde-forms">
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">
                        Enter Remark<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="form.comments" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                        </div>
                    </div>
                     <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#22690b;">Pay Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <!-- <input class="form-control form-cascade-control input-small"  v-model="form.nextpaymentdate"  type="date" required> -->
                            <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="form.nextpaymentdate">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <button type="button"
                                            class="date-picker"
                                            @click="togglePopover()">
                                            <i class='bx bx-calendar'></i>
                                        </button>
                                        <input :value="inputValue" @click="togglePopover()"
                                            class="form-control form-cascade-control input-small"
                                            placeholder="DD/MM/YYYY" readonly />
                                    </div>
                                </template>
                            </vc-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-primary" style="margin-top:0" @click="submit()">Submit</button>
                        <button type="button" class="btn btn-danger" @click="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props:['emiid'],
    data(){
        return {
            form:new window.Form({
                id:0,comments:'',nextpaymentdate:'',emiid:0
            }),
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
        }
    },
    mounted(){
        let param = {emiid:this.emiid}
        this.$http.post('api/ongoingschemes/emi/visitcomments/fetch',param)
        .then((response) => this.processDetailResponse(response.data))
        .catch((err) => {
            console.log(err)
        });
    },
    computed:{
        ...mapGetters([
		      'list'
	    ]),
    },
    methods:{
        processDetailResponse(data){
            this.$store.commit('assignlist',data)
        },
        submit(){
            this.form.emiid = this.emiid
            this.form.submit(this,'post','api/ongoingschemes/emi/visitcomments/createupdate')
            .then(response=>this.onSuccess(response))
            .catch(error=> console.log(error) );
        },
        onSuccess(data){
            this.$store.commit('assignlist',data)
            this.$parent.visitcomments = false
        },
        cancel(){
            this.$parent.showvisitcomments = false
            this.$parent.visitcomments = false
            
        },
        
    }
}
</script>