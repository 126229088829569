<template>
<div>
    <div class="card-body" style="border:1px solid #e9dbdb;font-sixe:13px;" v-if="searchdetails">
        <div class="col-md-12">
            <div class="row">
                <label class="col-lg-4 co-md-3 control-label "><h5> Search Records</h5></label>
            </div>
            <div class="form-group mb-2 ">
                <div class="row">
                    <div class="col-md-4 row" >
                       <label><b> Customer Name</b></label>
                       <input class="form-control form-cascade-control input-small" type="text" v-model="customername">
                     </div>
                    <div class="col-md-4 row">
                        <label><strong>VSDIGI Id</strong></label>
                        <input class="form-control form-cascade-control input-small" type="text" v-model="generatedid">
                    </div>
                    <div class="col-md-4 row">
                        <label><strong>Mobile</strong></label>
                        <input class="form-control form-cascade-control input-small" type="number" v-model="mobile">
                    </div>
                    <div class="col-md-4 row">
                        <label><strong>Address</strong></label>
                        <input class="form-control form-cascade-control input-small" type="text" v-model="address">
                    </div>
                    <div class="col-md-4 row">
                        <label><strong>Entry Date</strong></label>
                        <input class="form-control form-cascade-control input-small" data-val="true" v-model="entrydate" data-val-required="Required" id="REGDATE" name="REGDATE" type="date">
                    </div>
                    <div class="col-md-4 row"  v-if="loggedinuser?.role<2">
                        <label><strong>Entry By</strong></label>
                        <select v-model="entryby" class="form-control form-cascade-control input-small">
                            <option value="0">SELECT ENTRY BY</option>
                            <option v-for="filter in employees.filter(property => property.id ==loggedinuser.id )" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                        </select>     
                    </div>
                    <div class="col-md-4 row" v-if="loggedinuser?.role>=2" >
                        <label><strong>Entry By</strong></label>
                        <select v-model="entryby" class="form-control form-cascade-control input-small">
                            <option value="0">SELECT ENTRY BY</option>
                            <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                        </select>     
                    </div>
                    <div class="col-md-4 row">
                        <strong>Scheduled Date From</strong>
                        <input type="date" class="form-control form-cascade-control input-small"
                            v-model="scheduledatefrom" >
                    </div>
                    <div class="col-md-4 row">
                        <strong>Scheduled Date To</strong>
                        <input type="date" class="form-control form-cascade-control input-small"
                            v-model="scheduledateto" >
                    </div>
                    <div class="col-md-4">
                        <button @click="search()" class="btn bg-success text-white username btnsearch" style="margin-top:31px;" type="button">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Constants from '../../components/utilities/Constants.vue'
import { mapGetters } from 'vuex'
export default {
    props:['displayfor'],
    mixins:[Constants],
    data(){
        return {
            customername:'',
            mobile:'',
            address:'',
            generatedid:'',
            searchdetails:true,
            entrydate:'',
            entryby:0,
            scheduledateto:'',
            scheduledatefrom:''
        }
    },
    computed:{
			...mapGetters([
		        'searchdata','employees','loggedinuser'
		    ]),
    },
    mounted() {
        if(this.$route.params.id!=null){
            this.searchdetails=false
        }else{
            this.searchdetails=true
            this.refresh();
        }
	},
    methods:{
        reset(){
            this.customername ='',
            this.mobile='',
            this.address='',
            this.generatedid='',
            this.entryby=0,
            this.entrydate='',
            this.searchdetails=true
            this.scheduledatefrom=''
            this.scheduledateto=''
        },
        refresh(){
            this.searchdetails=true
            this.$store.dispatch('fetchemployees')
            this.reset();
        },
        search(){
            this.$store.commit("assignloadingstatus",1)
            let param = {name:this.customername,entrydate:this.entrydate,entrybyid:this.entryby,
                mobile:this.mobile,address:this.address,generatedid:this.generatedid,
                scheduledatefrom:this.scheduledatefrom,scheduledateto:this.scheduledateto,
                iscustomer:1}
            switch (this.displayfor) {
                case 1: //Crm sale data
                    this.$http.post('api/crm/fetch/user',param)
                    .then((response) => this.processResponseCRM(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                        this.$http.post("api/crm/fetch/usercount",param)
                    .then((response) => this.ProcessCRMcount(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                    break;
                case 2: // Crm scheduled Data
                    this.$http.post('api/crm/scheduled/users',param)
                    .then((response) => this.processResponseCRM(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                        this.$http.post("api/crm/scheduled/users/count",param)
                    .then((response) => this.ProcessCRMcount(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                    break;
                case 3: //Crm Disposed data
                    this.$http.post('api/crm/disposed/users',param)
                    .then((response) => this.processResponseCRM(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                        this.$http.post("api/crm/disposed/users/count",param)
                    .then((response) => this.ProcessCRMcount(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
                    break;
            
                default:
                    break;
            }
            
        },
        processResponseCRM(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assigncrmschedule',data)
        },
        ProcessCRMcount(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assigncrmschedulecount',data)
        },
        

    }
}
</script>